import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import fastclick from 'fastclick'
import VueLazyload from 'vue-lazyload'
import { createMetaManager } from 'vue-meta'
// import mitt from 'mitt'; 

// const emitter = mitt(); 
fastclick.attach(document.body)
const loadimage = require('./assets/logo.png')
const errorimage = require('./assets/logo.png')

const app = createApp(App);
    app.use(router)
    app.use(VueAxios, axios)
    app.use(VueCookieNext)
    app.use(Toast)
    app.use(VueLazyload, {
        preLoad: 1.3,
        error: errorimage,
        loading: loadimage,
        attempt: 1
    })
    app.use(createMetaManager())
    // app.provide('emitter', emitter); 
    app.mount('#app')

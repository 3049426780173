import { createRouter, createWebHistory } from 'vue-router'
// function lazyLoad(view){
//   return() => import(`@/views/${view}.vue`)
// }
import Home from '../views/Home.vue'
// import NotFounds from '../views/Page/NotFounds.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: lazyLoad('Home')
  // },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search/:search_id',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/page/Search.vue'),
    props: true
  },
  {
    path: '/content/:Content_id',
    name: 'Content',
    component: () => import(/* webpackChunkName: "content" */ '../views/page/Content.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/user/Unsubscribes.vue'),
    // meta: {
    //   IsAuth: true
    // }
  },
  {
    path: '/categories/:cat_id/:cat_name',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/category/Categories.vue'),
    props: true
  },
  // { path: '/:pathMatch(.*)*', name: 'NotFounds', component: NotFounds },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

// router.beforeEach((to, from, next ) => {
//   const { $cookie } = router.app.config.globalProperties
//    if(to.meta.IsAuth) {
//     if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
//       next();
//     } else {
//       next(window.open("https://dsplp.sd.zain.com/?p=2741755443", "_self"));
//     }
//    } else {
//      next();
//    }
// })

export default router

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} |  العريف` : `العريف` }}</template>
  </metainfo>
  <router-view :key="$route.fullPath"/>
</template>
<script>
import { useMeta } from 'vue-meta'
  export default {
    name: 'App',
    setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
  }
</script>
<style>

</style>
